import React from 'react'
import { Link } from 'gatsby'

import SEO from '../../components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from '../../components/layout'
import { ApplyAndWhatsNextSections, PositionIcon } from '../../components/pages/CareersPage'
import { Nav } from '../../components/nav'
import { LdJsonJobPosting, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const CareerPositionPage = () => (
  <Layout className="bg-primary-1 text-primary-3">
    <Analytics />
    <SEO title="Hands-on Team Lead" pageUrl="/careers/teamlead/" type="article" cardImage="/img/tumbacardjobs1.png" description="You are a multidisciplinary expert passionate about making a world-changing impact, Let's Talk!" />
    <LdJsonJobPosting title="Hands-on Team Lead" description="You are a multidisciplinary expert passionate about making a world-changing impact, Let's Talk!" publishDate="2024-04-05T13:09:57.000Z" minSalary="7000.00" maxSalary="10000.00" />
    <MainWithMenu className="relative grid">
      <Header className="z-20 pt-6 pb-24">
        <HeaderLink className="text-primary-2" />
        <Link to="/careers/">
          <div className="py-12 text-left">
            <h1 className="py-4 text-4xl lg:text-5xl text-primary-3 text-shadow">Careers</h1>
          </div>
        </Link>
      </Header>
      <Deco className="z-10 bg-primary-2" decoType={2} before after gridRow={2} />
      <Section className="z-20 lg:mr-48" gridRow={2}>
        <PositionIcon className="w-12 sm:w-20 text-primary-1 fill-current" />
        <h2 className="text-3xl lg:text-4xl text-primary-1">Hands-on Team Lead</h2>
        <div className="mt-4 mb-16 text-lg lg:text-xl tracking-normal">
          <p className="sm:mt-6 font-normal">
            <strong className="text-primary-1 font-normal">You are a multidisciplinary expert passionate about making a world-changing impact.</strong> Here, at Tumba Solutions, you'll have the chance to work with people like you - smart and nimble. People you
            are eager to work with on solving unique and complex technical challenges on a scale matched by few companies.
          </p>
          <p className="sm:mt-6 font-normal">
            No matter where your technical expertise is and what your personal goals for professional development are, <strong className="text-primary-1 font-normal">let's talk</strong>!
          </p>
          <p className="sm:mt-6">
            We have number of opportunities we could tackle <strong className="text-primary-1">together</strong>:
          </p>
          <ul className="pl-10 mt-2 list-disc font-normal">
            <li>Fleet Management systems.</li>
            <li>Map creation tools for point cloud.</li>
            <li>Sustainable Urban mobility.</li>
            <li>Digital Publishing.</li>
          </ul>
          <p className="sm:mt-6 font-normal">
            <em>What we are primarily looking for is a track record of building things that work and passion to make a better product.</em>
          </p>
        </div>
        <div className="grid gap-12 grid-cols-1 lg:grid-cols-2">
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">Main Responsibilities</h3>
            <div className="mt-4 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Hands-on implementation</strong> of key portions of the system.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Lead a</strong> small <strong className="text-primary-1 font-normal">team</strong> of developers and QAs to achieve goals, using a suitable software engineering approach.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Set team's</strong> short term and long term team <strong className="text-primary-1 font-normal">goals</strong>; ensure all team members understand the team mission.
                </li>
                <li className="mt-4">
                  Motivate team members, discover training needs and <strong className="text-primary-1 font-normal">provide coaching</strong>.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Communicating with</strong> internal and external <strong className="text-primary-1 font-normal">stakeholders</strong> and other team leads to identify the requirements for the product.
                </li>
                <li className="mt-4">
                  Work on <strong className="text-primary-1 font-normal">breaking down</strong> system <strong className="text-primary-1 font-normal">requirements</strong> into subsystem's functional and performance requirements.
                </li>
                <li className="mt-4">
                  Continually <strong className="text-primary-1 font-normal">drive alignment</strong> between product, technical requirements, architecture design and detailed implementation.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Take ownership</strong> of the product's quality and user experience.
                </li>
                <li className="mt-4">Help define test cases for development testing, verification and validation.</li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Manage product delivery</strong> timelines and take into account business, legislative and security requirements.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Be responsible</strong> for the team commitments and deliverables.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">Core Requirements</h3>
            <div className="mt-4 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">2+ years</strong> of technical leadership experience.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">7+ years</strong> of hands-on software engineering experience.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Advanced knowledge</strong> of software engineering principles including requirements gathering, software design, source control management, build processes, code reviews, testing methods.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Advanced knowledge</strong> of designing and developing distributed systems and native applications.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Advanced knowledge</strong> in two or more of the following languages: Java, Swift, Python, JavaScript, Kotlin.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Good understanding</strong> in two or more of the following platforms: Android, Linux, iOS, Java
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Good understanding</strong> in at least one of the following cloud providers: AWS, GCP, Azure
                </li>
                <li className="mt-4">Self-organized, proactive and good communicator.</li>
                <li className="mt-4">Able to look through the customer lens.</li>
              </ul>
            </div>
          </div>
        </div>
      </Section>
      <ApplyAndWhatsNextSections position="Hands-on Team Lead" active={false} />
      <Nav gridRow={7} className="z-20 text-primary-2" />
    </MainWithMenu>
  </Layout>
)

export default CareerPositionPage
